.cat-navbar-container{
    display: none;
    flex-flow: row wrap;
    font-size: calc(10px + 2vmin);    
    background-color: #333;
    opacity: 1; 
}

.category-nav-text {
    align-self: flex-start;
    margin-left: 1em;
    width: 250px;
    text-align: left;
    letter-spacing: 1px;
}

@media screen and (max-width: 992px)  {

    .cat-navbar-container {
        display: flex;
        flex-direction: column;
    }

    .category-nav-view-item{
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        border-radius: 2px;
        height: 50px;
        width: 160px;
        color: rgb(242, 236, 255);
        font-size: 16px;
        font-weight: 700;
        text-align: center;    
        margin-top: 5px;
        margin-bottom: 5px;
        cursor: pointer;
        transition: 0.2s;
    }
    
    .category-nav-view-item:hover{
        color: #7e7e7e;
    }
    
    .submenu-container{
        display: flex;
        flex-flow: row nowrap;
        justify-content:space-evenly;
        align-items: center;
        font-size: calc(10px + 2vmin);
        background-color: #BEB7DF;
        opacity: 0.7;
        height: 200px;
        width: 200px;
    }
    
    .category-nav-text {
        align-self: flex-start;
        margin-left: 1em;
        width: 200px;
        text-align: left;
    }
    
    .category-text-visited{
        color:#747474;
    }
}
