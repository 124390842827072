.form-title {
    margin: 1.3em 0 1em;
    font-size: 1.8em;
}

.form {
    width: 330px;
    margin-bottom: 2em;
}

.hello {
    margin-left: 1px;
}

.form label {
    color: #333;
    margin: 1em auto 0.5em;
    display: flex;
    font-weight: bold;
}

.form label span {
    color: rgb(226, 17, 17);
}

.form .fieldclass {
    background-color: #fafafa;
}

.form-textarea {
    color: #333;
    margin: 1em auto 0.5em;
    display: flex;
    font-weight: bold;
}

.form-textarea span {
    color: rgb(226, 17, 17);
}

.submit-form {
    /* padding: 10px; */
    width: 100px;
    height: 40px;
    margin: 3em;
    border-radius: 4px;
    background-color: #333;
    border: none;
    outline: none;
    cursor: pointer;
}

.submit-form:hover {
    background-color: #4a4a4a;
}

#error-message {
    color: rgb(223, 28, 28);
    font-size: 0.8em;
}

@media (min-width: 640px) {
    .form-title {
        margin: 1.5em 0 1.2em;
        font-size: 2em;
    }

    .form {
        width: 600px;
    }

    .submit-form {
        /* padding: 10px; */
        width: 160px;
        height: 50px;
        margin: 3em;
        border-radius: 4px;
        background-color: #333;
        outline: none;
        cursor: pointer;
    }
}

@media (min-width: 1050px) {
    .form-title {
        margin: 1.8em 0 1.3em;
        font-size: 2.4em;
    }

    .form {
        width: 730px;
    }

    .submit-form {
        width: 170px;
        height: 55px;
        margin: 3em;
        border-radius: 4px;
        background-color: #333;
        outline: none;
        cursor: pointer;
    }

    #error-message {
        font-size: 1em;
    }
}