.disable-paypal-buttons {
  pointer-events: none;
  opacity: 0.7;
}

.paypal-error{
  color: rgb(214, 59, 59);
  font-weight: 600;    
}

.checkout-body-container {  
display: flex;  
padding-left: 10vh;
padding-right: 10vh;
}

.checkout-order-table-title{
  display: flex; 
  width: 100%; 
  align-items: baseline; 
  justify-content: left;
  font-size: calc(22px + 0.6vw);
}
.checkout-order-table {
border-style: solid;
border-width: 1px;
border-color: gray;
border-radius: 5px;
font-size: calc(12px + 0.3vw);
}

.checkout-order-table-paymentType {
display: flex;
width: 100%;
height: 4vh;
background-color: lightgray;   
align-items: baseline;  
justify-content: flex-start;
padding: 5px;
border-bottom-width: 1px;
border-bottom-style: solid;
border-bottom-color: grey;
}

.checkout-order-table-terms {
width: 100%;
min-height: 5vh;  
background-color: lightgray;  
display: flex; 
flex-direction: column;  
align-items: flex-start;  
justify-content: space-between;
padding-left: 5px;
padding-top: 10px;
font-size: calc(10px + 0.3vw);  
text-align:start;
}

.checkout-order-table-acceptTerms {
width: 100%;
min-height: 4vh;
background-color: lightgray;  
display: flex;
align-items: baseline; 
justify-content: flex-start;
padding-left: 5px;
text-align:start;
}

.checkout-order-table-header {
width: 100%;
height: 4vh;
background-color: #c3c3c3; 
display: flex;
align-items: baseline; 
justify-content: space-between;
color: #fff;
border-style: none;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
border-bottom-width: 3px;
border-bottom-style: solid;
border-bottom-color: #d9d9d9;
padding: 5px;
}

.error-message{
color: red;
}

.checkout-order-table-body {
width: 100%;
height: auto;
background-color: lightgray;  
display: flex; 
flex-direction: column;
}

.checkout-order-table-component {
width: 100%;
height: 4vh;
background-color: lightgray; 
display: flex;  
align-items: baseline;  
justify-content: space-between;
padding-left: 5px;
padding-right: 5px;
padding-top: 5px;
border-bottom-width: 1px;
border-bottom-style: solid;
border-bottom-color: grey;
text-align: start;
}

#ch-tb-price {
color: #007bff;
}

.checkout-order-table-subtotal {
width: 100%;
height: 4vh;
background-color: lightgray; 
display: flex;
align-items: baseline;  
justify-content: space-between;
border-bottom-width: 1px;
border-bottom-style: solid;
border-bottom-color: grey;
padding: 5px;
}

.checkout-order-table-footer {
width: 100%;
min-height: 10vh;
}

.checkout-paypal {
  width: 100%;      
  display: flex;    
  align-items: center;    
  justify-content: center;    
  flex-direction: column;
  margin-top: 2vh;
}

.checkout-order-table-submit {
  width: 100%;
  height: 15vh;
  padding: 10px;
  background-color: lightgray;  
  display: flex;    
  align-items: center;   
  justify-content: center;  
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-style: none;
}

.row-container {
  display:flex;
  flex-direction: row;    
  width: '100%'; 
  justify-content: 'space-between';
}

.three-quarters-container{    
  flex: 3 75%;    
}

.one-quarter-container-right{    
  flex: 1 25%;
  margin-left: 1vw;
}

.two-quarters-container {    
  flex: 1 50%; 
}

.two-quarters-container-right {    
  flex: 1 50%; 
  margin-left: 1vw;
}

@media screen and (max-width: 1000px) {
  .checkout-body-container {    
    flex-direction: column;
    margin-bottom: 10px;
  }
  .checkout-order-table-paymentType {
    height: 6vh;
  }
  .checkout-body-container {
    padding: 0; 
  }
  .checkout-order-table-component {     
    height: 6vh;
  }
}

.checkout-paypal-buttons {
  width: 50vw;
  height: 100vh;   
}