.add-address-container {
    width: calc(230px + 27vw);
    height: 700px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #ffffff;
    margin-top: 3vh; 
  }

  @media screen and (max-width: 767.98px) {
    .add-address-container {
        width: 96vw;
    }
  }
  
  .address-modal-header{
    width: 100%;
    display: flex;  
    flex-direction: row;
    align-items: flex-start;
  }
  
  .edit-address-title {
    display: flex;
    flex-direction: row;
    flex: 7;
    justify-content: center;
    padding-left: 5vw;
  }
  
  .edit-address-close-button {
    display: flex;
    flex-direction: row;
    flex: 1.2;
    border: none; 
    background-color: #fff; 
    outline: none !important;  
  }