.cart-body {
    min-height: 50vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
            justify-content: flex-start;
  }
  
  .cart-body .empty-cart {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 90%;
    color: #777;
    /* background-image: url('../../components/img/emptyCart.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 400px;
    height: 400px;
  }
  
  .cart-body .cart-title {
    width: 90%;
    height: 10vh;
    color: #333;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
            justify-content: flex-start;
    border-bottom: #d6d6d6;
    border-style: solid;
    border-width: 2px;
    border-top: none;
    border-left: none;
    border-right: none;
    margin-bottom: 3vh;
    font-weight: bold;
    font-size: calc(1.5em + 0.8vw);
  }
  
  .cart-body .table-container {
    width: 100%;    
    display: flex;
    flex-direction: column;
    align-items: center;    
    justify-content: center;
    padding: 5vh;    
  }
  
  .cart-body .table-container .table {   
    display: flex;    
    flex-direction: column;
    width: 100%;      
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    border-radius: 5px;
  }
  
  .cart-body .table-container .table .table-header {
    width: 100%;
    height: 4vh;
    background-color: #c3c3c3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-width: 3px;
    border-bottom-color: #d9d9d9;
    border-bottom-style: solid;   
    display: flex;   
    align-items: center;    
    justify-content: center;
    padding-top: 0.5vh;
    padding-right: 5vh;
    color: #fff;
  }

  @media screen and (max-width: 768px){
    .cart-body .table-container .table .table-header {
        display: none;
    } 

  }
  
  .cart-body .table-container .table .table-footer {
    width: 80%;
    align-self: center;
    display: flex;    
    align-items: center;    
    justify-content: center;  
    padding: 0 5vw;
    flex-wrap: wrap;
  }
.flex-1{
    display: flex;
    flex: 1;
    margin: 1vw;
  }

  .cart-total-container {
    width: 100%;
    height: 40vh;  
    display: flex;    
    align-items: center;   
    justify-content: center;   
    padding-right: 5vh;
    padding-bottom: 5vh;
  }
  
  .cart-total-container .cart-total-table {
    width: 35vh;
    height: 30vh;
    border-style: solid;
    border-width: 1px;
    border-color: grey;
    border-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
            justify-content: flex-start;
  }
  
  .cart-total-container .cart-total-header {
    width: 100%;
    height: 4vh;
    background-color: #c3c3c3;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-width: 3px;
    border-bottom-color: #d9d9d9;
    border-bottom-style: solid;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: flex-start;
        -ms-flex-pack: flex-start;
            justify-content: flex-start;
    padding-left: 1vh;
    padding-top: 1vh;
    color: #000;
  }
  
  .cart-total-container .cart-total-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 20vh;
    background-color: #f2f2f2;
    border-bottom-width: 2px;
    border-bottom-color: #d9d9d9;
    border-bottom-style: solid;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 10px;
  }
  
  .cart-total-container .cart-total-footer {
    width: 100%;
    height: 6vh;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: none;
            flex-direction: none;
  }
  