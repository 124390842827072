.cart-button {
    background-color: transparent;
    padding-bottom: 5px;
    transition: 0.2s;
    color: #f2ecff;
}
.cart-button:hover {
    opacity: 0.75;  
}

.badge {
    color: #f2ecff;
}