.product-card {
    position:relative;
    height: 350px;
    width: 350px;
    padding: 1em 2em 2em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #efefef;
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 34%);
    margin-right: 0vw;
    margin-bottom: 4vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .shoppingCart-button img {
    width: 100%;
    height: 200px;
    border: none;
    outline: none;
  }

  @media screen and (max-width: 992px) {
    .product-card {
      height: 280px;
      width: 290px;
      margin: 1em auto;
    }
    .shoppingCart-button img {     
      height: 144px;
    }
  }

  .title-container{
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;   
    font-size: calc(10px + 0.5vw);
    font-weight: bold;
    text-align: center;
    overflow: hidden;   
    width: 100%;
    white-space: normal;
    letter-spacing: 1.3px;
    color: #5F6A72;
  }

  .product-price-container {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;    
    align-items: flex-end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .prices-container{
      display: flex;
      justify-content:flex-start;
      align-items:baseline;  
      font-size: calc(12px + 0.5vw); 
      margin-bottom: -15px;
      border-top: 1px solid #A6CBBE;  
  }

  .old-price{
    position: relative;
    margin-right: 3px;
    color: #6c6f74;
  }

  .old-price::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #383636;
    left: 0;
    top: 50%;    
  }

  .shoppingCart-button {
    border: none;
    outline: none !important;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background-color: inherit;
  }

  .new-label-container {
    position: absolute;
    top: 0px;
    left: 0px;
    border-top-left-radius: 4px;
    padding: 4px;
    background-color:rgb(248, 189, 28);    
  }

  .discount-label-container {
    position: absolute;
    top: 30%;
    left: 0px;
    border-bottom-left-radius: 4px;
    padding: 4px;
    background-color:rgb(11, 202, 170);
  }

  .hot-label-container {
    position: absolute;
    top: 0px;
    right: 0px;
    border-top-right-radius: 4px;
    padding: 4px;
    background-color:rgb(202, 11, 11);
  }  

  .label {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0px;
  }

  .soldout-img {
    width: calc(35px + 3.5vw);   
    margin-bottom: -1vw; 
  }

  .next-btn{
    color: #ff9900;
    size: 2vw;
  }