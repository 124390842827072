.cart-object {
    width: 100%;
    min-height: 7vh;
    background-color: #f2f2f2;   
    display: flex;    
    align-items: center;    
    justify-content: center; 
    padding-left: 2vh;
    padding-right: 5vh;
    border-bottom-color: lightgray;
    border-bottom-width: 0.5px;
    border-bottom-style: solid;
    font-size: calc(12px + 0.3vw);   
  }

 
  .cart-object .cart-icons {  
    display: flex; 
    flex: 3;
    align-items: center;    
    justify-content: flex-start;
  } 

  .cart-object .item-element-container{
    display: flex;
    flex: 2;
    align-items: center; 
    justify-content: center;
  }
  
  .cart-object .close-button {
    border: none;
    outline: none !important;
    margin-right: 5vw;
  }
  
  .cart-object .link-button {
    border: none;
    outline: none !important;
    color: #007bff;
    min-height: 5vh;
    height: auto;
    width: auto;
  }
  
  .cart-object .link-button:hover {
    text-decoration: underline;
  } 
  
  .item-element-title{
        font-weight:700;
        display: none;
  }

  @media screen and (max-width: 768px){
    .cart-object {
        flex-direction: column;
        align-items:flex-start;
        padding: 0px;
    } 
    .cart-object .cart-icons,  .cart-object .item-element-container {
        width: 100%;
        padding: 2vw;
        border: 0.5px solid lightgray;
        justify-content: space-between;
    }
    .item-element-title{        
        display: block;
    }
    .cart-object .item-element-container:nth-child(5) {
        border-bottom: 3px solid rgb(73, 69, 69);
    }

  }
  