.cat-bar-container{
    display: flex;
    flex-flow: row wrap;   
    justify-content:space-evenly; 
    align-items: center; 
    font-size: calc(10px + 2vmin);    
    background-color: #d6d6d6; 
    opacity: 1; 
}

@media screen and (max-width: 992px)  {
     .cat-bar-container {
         display: none;
     }   
}

.category-view-item{
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    border-radius: 2px;
    height: 50px;
    width: 250px;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
    text-align: center;    
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: 0.2s;
    letter-spacing: 2px;
}

.category-view-item:hover{
    color: #7e7e7e;
}

.submenu-container{
    display: flex;
    flex-flow: row nowrap;   
    justify-content:space-evenly; 
    align-items: center; 
    font-size: calc(10px + 2vmin);    
    background-color: #BEB7DF; 
    opacity: 0.7;
    height: 200px;
    width: 200px;
}

.category-text-visited{
    color:#747474;
}