  .top-bar-nav {   
    display: flex;
    flex-flow: row nowrap; 
    align-items: center; 
    justify-content:space-between;
    font-size: calc(10px + 2vmin);
    color: #f2ecff;
    background-color: #333333;  
  }
 
  .navbar-collapse.show {
    background-color: #333333;
}

.Artist-Name:hover {
  color: #dddddd;
}

.navbar-collapse.show .nav-item{
  padding-top: 10px;
}

.navbar-collapse.show .checkout-button-container{ 
  display: flex;
  color:#f2ecff;
  text-decoration: none;  
}

.nav-nav-left, .nav-nav-fill, .nav-nav-right {
    display:flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
  
  .nav-nav-left{
    margin-left: 3em;
  }
  
  .nav-nav-fill{    
    justify-content: center;
    flex-wrap:nowrap;
  }

  #btn-menu {
    left: auto !important;
    right: 0px;
}

.nav-SignIn {
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
            margin-left: 5px;
  } 

.nav-button-SignIn {
    outline: none !important;    
    border: none;
    font-size: 16px;
    background-color: transparent;
    color: #F2ECFF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;
    transition: 0.2s;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .nav-button-SignIn:hover {
    opacity: 0.75;
  }

  .phone-nav {
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .phone-p {
    margin-bottom: 1px;
    margin-left:2px;
    color: #f2ecff;
    font-size: 16px;
  }

.button-SignIn p {
    font-size: 16px;
    margin-bottom: 1px;
  }

.nav-cart-button-container {
    width: 10vh;
    height: 100%;
    border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
            margin-left: 7px;
  }

  .nav-cart-show{
    display:none;
  }

  .phone-number {
    display:inline;
  }

  @media screen and (max-width: 992px)  {
    .nav-cart-show{
      display:flex;
      margin-left: 0px;
      margin-right: 0px;
    }

    .nav-nav-left{
      margin-left: 0.8em;
    }

    .phone-number {
      display:none;
    }

    .language-flags {
      align-self: flex-start;
      margin: 15px;
    }

    .nav-cart-button-container {
      justify-content: start;
    }
  }
  
  .account-btn {
    outline: none !important;
    border: none;
    background-color: #131921;
    color: #f2ecff;
    font-size: 18px;
    margin-left: 5px;
  }

  .checkout-button-container{
    font-size: 16px;
    display:none;
  }

  .language-styling{
    font-size: 16px;
  }

  #navbarNavAltMarkup {
    align-items: flex-end;
    flex-direction: column;
    margin-right: 3em;
  }