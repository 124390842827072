@import "black-orange-theme.css";

body{
  padding:0px;
  margin: 0;
  width: 100%;
}

.body-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

/* .column-flex-center{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
    flex-direction: column; 
} */

.row-flex-start-wrap{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;    
    -webkit-box-pack: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    flex-wrap:wrap
}

.row-flex-between-wrap{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;    
    -webkit-box-pack: justify;
      -ms-flex-pack: justify;
    justify-content: space-between;
    flex-wrap:wrap
}

.row-flex-center{
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;    
    align-items: center;
    justify-content: center;
    flex-wrap:wrap
}

.column-flex-start{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  -wrap: wrap;
}

.search-box{
  width: auto;
  background-color: #fff;
}

.top-bar img {
  margin-left: 0rem;
  margin-top: 0.5rem;
}

.top-bar .Social {
  width: 10vh;
  height: 100%;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.top-bar .phone {
  width: 17vh;
  height: 100%;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  font-size: 16px;
}

.top-bar .phone p {
  margin-bottom: 1px;
  color: #fff;
}


.top-bar .button-social {
  width: 100%;
  height: 30px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: none;
          flex-direction: none;
  background-color: #131921;
  outline: none !important;
}


.header {
  min-height: 30vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: none;
          flex-direction: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

@media screen and (max-width: 1000px) {
  .header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 10px;
    padding-top: 5vh;
    padding-bottom: 5vh;
  }
}

.header-adv {
  width: 450px;
  height: 150px;
  background-image: url("../components/img/woman-online.png");
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: none;
          flex-direction: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.logo-container {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.logo-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: auto;
  width: auto;
}

.logo-subtitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  height: auto;
  width: auto;
}

.nav-bar {
 
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: none;
          flex-direction: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  
}


.product-container .products {
  display: inline-block;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  vertical-align: text-top;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: auto;
  max-width: 130vh;
}

.popular-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
}

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.total-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 100%;
  height: 3vh;
  padding-left: 2vh;
}

.category-title {
  min-height: 20vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
}

.category-product-container {
  min-height: 60vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
  padding-top: 30px;
}

.product-evaluation-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
  -ms-flex-item-align: center;
      align-self: center;
  min-width: 100vh;
  max-width: 200vh;
  min-height: 30vh;
  padding-top: 3vh;
  margin-left: 10vh;
  margin-right: 10vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.product-evaluation-container .product-evaluation-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-top: 5vh;
}

.product-evaluation-container .product-evaluation-body Textarea {
  width: 100%;
  margin-bottom: 10px;
}

.product-evaluation-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
    height: 3vh;
    font-weight: bold;
    border-bottom-width: 1px;
    border-bottom-color: grey;
    border-bottom-style: solid;
    padding: 5px;
    margin-top: 5vh;
  }

.rated-card {
  width: 100%;
  height: auto;
  border-width: 0px;
  border-style: solid;
  border-color: grey;
  padding: 5px;
  background-color: #faf6ce;
  margin-bottom: 15px;
}

.rated-card .rated-card-title {
  width: 100%;
  height: 6vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
}

.rated-card .rated-card-commentText {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
  width: auto;
  height: auto;
}

.scroll-view {
  margin-top: "10px";
  display: "flex";
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: "row";
          flex-direction: "row";
  overflow-y: hidden;
  white-space: nowrap;
  list-style-type: none;
  padding-left: 20px;
  background-color: #e2e2e2;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  height: 70px;
  justify-items: center;
  height: auto;
}

@media screen and (max-width: 800px) {
    .product-mainBody {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
  }


.option-item:hover {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

label {
  color: black;
}

.modalContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.modalTitleContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-bottom: 2px solid #545454;
}

.modalFormContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 24vh;
  width: 70%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 20px;
}

.modalRegisterButtonContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
  width: 100%;
  margin-top: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 10px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  border-top: 1px solid #919191;
  border-bottom: 1px solid #919191;
}

.modalRegisterFormContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 33vh;
  height: auto;
  width: 70%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 20px;
}

.OR {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-item-align: center;
      align-self: center;
  width: 80px;
}

.hr {
  width: 100%;
}

.maybe {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-item-align: center;
      align-self: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}

.modalFacebookContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
  width: 70%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 20px;
}

.modalExitButtonContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: auto;
  width: 90%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 10px;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.resetPasswordModal {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.passwordPic {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-image: url("../components/img/pass.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 80px;
  height: 80px;
}

.modalVerificationCodeContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  min-height: 33vh;
  width: 70%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 20px;
}

.modalVerificationCodeBox {
  height: 50px;
  width: 50px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #cde6dd;
  border: none;
  outline: none;
  text-align: center;
  font-size: 20px;
}

.confirmationModalButtonsView {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.change-password {  
  display: flex;  
  align-items: center; 
  justify-content: center;  
  width: 100%;
  height: 40px;
  border-width: 1px;
  border-color: grey;
  border-style: solid;
  background-color: lightgray;  
  justify-content: space-between;
  padding-left: 2vh;
}

.change-password .changePassTitle {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: lightgray;
}

.change-password button {
  outline: none;
  border: none;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: lightgray;
}

.change-pass-container { 
  display: flex;
  
          align-items: center;
 
          justify-content: space-around;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
          margin-bottom: 3em;
}

.settingsFieldSub {
  height: 400px; 
  display: flex;  
  align-items: center; 
  justify-content: space-between;  
  flex-direction: column;
  background-color: #ffffff;
  margin-top: 3vh;
}

.history-container { 
  display: flex;  
  align-items: center;  
  justify-content: center;  
  flex-direction: column;
  width: 100%; 
  padding: 10px;
}

.history-boject-container {
  overflow: auto;
  min-height: 40vh;
  max-height: 50vh;
  width: 100%;
}

.history-object { 
  width: 100%;
  height: auto;
  min-height: 10vh;
  margin-bottom: 20px;
}

.history-object-topHeader {
  background-color: #c3c3c3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-color: #d9d9d9;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  padding-left: 10px;
  padding-right: 10px;
  height: 3vh;
}

.history-object-header {
  background-color: #c3c3c3;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-color: #d9d9d9;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  padding-left: 10px;
  padding-right: 10px;
  height: 3vh;
}

.history-object-body-container {
  height: auto;
  width: 100%;
  min-height: 3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.history-object-body {
  height: auto;
  height: 3vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  padding-left: 10px;
  padding-right: 10px;
  background-color: whitesmoke;
}

.history-object-footer {
  height: 3vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
  padding-left: 10px;
  padding-right: 10px;
  border-top-width: 1px;
  background-color: #c3c3c3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.history-object-link {
  outline: none;
  border: none;
  background-color: whitesmoke;
  width: auto;
  height: 3vh;
  color: #007bff;
}

.history-object-link :hover {
  text-decoration: underline;
}

.wishlist-object {
  width: 100%;
  height: 8vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;    
  align-items: center;  
  justify-content: space-between;
  border-style: solid;
  border-width: 2px;
  border-color: #ffdede;
  background-color: whitesmoke;
  border-radius: 5px;  
  box-shadow: 0px 0px 3px -2px rgba(0, 0, 0, 0.89);
  font-size: calc(12px + 0.3vw);
  margin-bottom: 20px;  
}

.wishlist-object button {
  border: none;
  background-color: whitesmoke;
  outline: none !important;
}

.wishlist-object img {
  width: 6vh;
  height: 6vh;
  border-radius: 5px;
}

.wishlist-object-delete {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 10%;
}

.wishlist-object-image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 10%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-left: 15px;
}

.wishlist-object-link-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 50%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.wishlist-object-title {
  color: #007bff;
}

.title-link {
  border: none;
  background-color: whitesmoke;
  outline: none !important;
  width: 100%;
  height: 4vh;
}

.title-link :hover {
  text-decoration: underline;
}

.wishlist-object-end {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 12vh;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 100%;
}

.dash-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .dash-topBar {
    width: 100%;
    height: 6vh;
    background-color: #23282d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-left: 2vh;
    padding-right: 2vh;
    color: #979ca1;
  }
  
  .logo-btn {
    width: 15vh;
    height: 100%;
    outline: none !important;
    border: none;
    background-color: #23282d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    color: #979ca1;
    text-decoration: none;
  }
  
  .logo-btn p {
    margin-top: 15px;
  }
  
  .logo-btn :hover {
    color: #fff;
  }
  
  .admin-menu-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 22vh;
    height: auto;
    background-color: #23282d;
    position: absolute;
  }
  
  .admin-menu-container button {
    outline: none !important;
    border: none;
    background-color: #23282d;
    color: #979ca1;
  }
  
  .admin-menu-container p:hover {
    color: #007bff;
  }
  
  .admin-btn-container {
    width: 18vh;
    height: 5vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .admin-btn-container p {
    margin-top: 15px;
    color: #979ca1;
  }
  
  .admin-btn {
    width: 3vh;
    height: 5vh;
    outline: none !important;
    border: none;
    background-color: #23282d;
    color: #979ca1;
    text-decoration: none;
  }
  
  .admin-btn :hover {
    color: #fff;
  }
  
  .admin-btn .admin-icon {
    font-size: 30px;
  }
  
  .logo-img {
    width: 6vh;
    height: auto;
  }
  
  .dash-body-container {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  
  .dash-controls {
    width: 20vh;
    height: 100vh;
    background-color: #23282d;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .dash-body {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  
  .dash-control-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    width: 100%;
    height: auto;
    outline: none !important;
    border: none;
    background-color: #23282d;
    color: #979ca1;
  }
  
  .dash-control-btn p {
    margin-top: 15px;
  }
  
  .dash-control-btn:hover {
    background-color: black;
    color: #007bff;
  }
  
  .dash-control-btn-active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    width: 100%;
    height: auto;
    outline: none !important;
    border: none;
    background-color: #32373c;
    color: #fff;
  }
  
  .dash-control-btn-active p {
    margin-top: 15px;
  }
  
  .dash-control-submenu {
    width: 100%;
    height: auto;
    background-color: #747a81;
    padding-left: 10px;
  }
  
  .dash-control-submenu button {
    outline: none !important;
    border: none;
    background-color: #747a81;
    color: #fff;
    width: 100%;
    height: 3vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .dash-control-submenu button :hover {
    color: blue;
  }
  
  .dash-control-submenu button p {
    margin-top: 15px;
  }
  
  .order-body-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #f1f1f1;
  }
  
  .dash-order-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-left: 20px;
    width: 100%;
    height: 10vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .dash-order-title h3 {
    margin-right: 10px;
  }
  
  .dash-order-grouping {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-left: 20px;
    width: 100%;
    height: 5vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .dash-order-grouping button {
    outline: none !important;
    border: none;
    color: #007bff;
  }
  
  .dash-order-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-left: 20px;
    width: 100%;
    height: 5vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .mash-action {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-left: 20px;
    width: 100%;
    height: 10vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .mash-action h5 {
    margin-right: 10px;
  }
  
  .mash-action button {
    margin-left: 10px;
  }
  
  @media screen and (max-width: 1000px) {
    .mash-action {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin-bottom: 10px;
      padding-top: 5vh;
      padding-bottom: 10vh;
    }
    .mash-action button {
      margin-top: 10px;
    }
  }
  
  .dash-order-table-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    padding: 10px;
    margin-top: 10px;
  }
  
  .dash-order-table {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100vh;
    height: auto;
    border-color: lightgray;
    border-width: 1px;
    border-style: solid;
  }
  
  .dash-order-table-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 5vh;
    background-color: #fff;
    border-bottom-color: lightgray;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding-right: 10px;
  }
  
  .dash-order-table-check {
    height: 100%;
    width: 6vh;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  
  .dash-order-table-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 30vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    color: #007bff;
  }
  
  .dash-order-table-title p {
    padding-top: 13px;
  }
  
  .dash-order-table-title button {
    outline: none !important;
    border: none;
    background-color: #fff;
  }
  
  .dash-order-table-total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 10vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    color: #007bff;
  }
  
  .dash-order-table-total p {
    padding-top: 13px;
  }
  
  .dash-order-table-total button {
    outline: none !important;
    border: none;
    background-color: #fff;
  }
  
  .dash-order-table-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: auto;
  }
  
  .dash-order-table-object {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 100%;
    height: 6vh;
    background-color: #f9f9f9;
    border-bottom-color: lightgray;
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
  
  .dash-order-object-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 30vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  
  .dash-order-object-title p {
    padding-top: 13px;
  }
  
  .dash-order-object-title button {
    outline: none !important;
    border: none;
    background-color: #f9f9f9;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    color: #0073aa;
    padding-left: 0px;
    padding-top: 5px;
  }
  
  .dash-order-object-title button :hover {
    text-decoration: underline;
  }
  
  .dash-order-object-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 65%;
    height: 70%;
    border-radius: 5px;
  }
  
  .dash-order-object-total {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    width: 10vh;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    padding-right: 10px;
  }
  
  .dash-order-object-total p {
    padding-top: 13px;
  }
  
  .dash-order-table-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 10vh;
    width: 100%;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-right: 10px;
  }
  
  .footer-data-display-counter {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    width: 530px;
    margin-left: 100px;
  }
  
  .footer-data-display-counter button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    outline: none !important;
    border-width: 1px;
    border-color: lightgray;
    border-radius: 5px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 30px;
    height: 30px;
  }
  
  .footer-data-display-counter p {
    margin-top: 15px;
  }
  
  .address-object {
    width: 100%;
    height: 8vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;     
    align-items: center;   
    justify-content: space-between;
    border-style: solid;
    border-width: 2px;
    border-color: #ff9900;
    background-color: whitesmoke;
    border-radius: 5px;    
    box-shadow: 0px 0px 8px -1px rgba(0, 0, 0, 0.89);
    font-size: calc(12px + 0.3vw);
    margin-bottom: 20px;  
  }

  .address-object-radio {  
    display: flex;
    flex: 2;   
    align-items: center;    
    justify-content: flex-start;
    margin-left: 1vw;    
    margin-bottom: -10px;
  }

  .adddress-object-info-container {    
    display: flex;    
    flex:6;   
    align-items: center;   
    justify-content: flex-start;
  }

  .address-object-end {   
    display: flex;    
    flex:1; 
    align-items: center;
    justify-content: flex-start;    
  }

  .addressLabel {
    color: #045dc2;    
  }

  .addNewAddressButtonsContainer {
    display: -webkit-box;
    display: -ms-flexbox; 
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    width: 90%;
    height: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
        justify-content:  space-evenly;
        margin: 0 auto;
  }

.typeAddressButton {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
      align-items: center;
}

.addressAddContainer {
    width: 90%; 
    height: 300px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 10px;
    flex-direction: column;
  }

  .addAddressOptions {
    width: 300px;
    height: 150px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
  }
  
  .addAddressOptionsButton {
    width: 300px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }
  
  .addAddressOptionsButton-label {
    margin-right: 20px;
    background-color: cornsilk;
    width: auto;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 12px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22);
  }

  .category-pic {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    resize: both;
    background-size: cover;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 900px) {
    .category-pic{
      background-position: right;
    }
  }
  @media (max-width: 770px) {
    .category-pic {
      display: none;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    background-color: #dedede;
    height: auto;
  }

  .grey-background{
    background-color: #dedede;
  }

  .categoryView {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color:#fff;
    align-items: center;
    border-radius: 10px;
    justify-content: center;
    padding: 20px;
    text-align: justify;
    padding: 2em 1.5em;
    width:  80%;
    margin: 2em auto;
    height: 100%;
  } 

  @media (max-width: 850px) {
    .categoryView {
      width: 100%;
      margin: 0;
      padding: 3em 2em;
    }
    .categoryView p {
      font-size: 14px;
    }
  }

  .categoryView p {
    border: 1px solid rgb(206 206 206);
    width: 95%;
    border-radius: 10px;
    padding: 20px 25px;
    box-shadow: 0px 0px 15px 0px rgba(125,104,107,0.53);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(125,104,107,0.53); 
  }

  .alert-enter {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  
  .alert-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
  }
  
  .alert-exit {
    opacity: 1;
  }
  
  .alert-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    -webkit-transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
  }

  /* This fires as soon as the element enters the DOM*/
.cart-transition-enter {
  /*We give the list the initial dimension of the list button*/
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: #5a564c;
  }
  /* This is where we can add the transition*/
  .cart-transition-enter-active {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: #9e8949;
  transition: all 400ms;
  }
  /* This fires as soon as the this.state.showList is false */
  .cart-transition-exit {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: #9e8949;
  }
  /* fires as element leaves the DOM*/
  .cart-transition-exit-active {
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: #5a564c;
  transition: all 400ms;
  }

  .cart-updated-alert{
    position: fixed;
    top: 40px;
    right: 40px;
  }

  @media screen and (max-width: 767.98px) {
    .cart-updated-alert{     
      right: 5px;
    }
  }

/*# sourceMappingURL=style.css.map */