.product-view-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-mainBody {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content:center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  border-top-width: 1px;
  border-top-color: grey;
  border-top-style: solid;
  margin-bottom: 8em;
}

@media screen and (max-width: 671px) {
  .product-mainBody {
    margin-bottom: -2em;
  }
}

.product-images-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1 ;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
     
}

.product-images {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 10px;   
}

.product-info {   
  min-height: 50vh;
  padding-left: calc(20px + 1vw);
  padding-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-self: flex-start;
}
.product-main-image {
  width: 50vh;
  height: 50vh;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  padding: 5px;
  overflow: hidden;
}

.hl-image {
  width: 100%;
  height: 100%;
  -webkit-animation: flip-scale-down-diag-2 0.5s linear both;
          animation: flip-scale-down-diag-2 0.5s linear both;
    -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
  -webkit-transition: 1s;
  transition: 1s;
}

.hl-image:hover {
  -webkit-transform: scale(1.5, 1.5);
          transform: scale(1.5, 1.5);
}

.product-collection-images {
  display: inline-block;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
  vertical-align: text-top;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  height: auto;
  max-width: 130vh;
 
}

.product-collection-images button {
  width: 115px;
  height: 105px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  padding: 5px;
  border-color: grey;
  border-width: 1px;
  border-style: solid;
  outline: none;
}

.product-collection-images button img {
  width: 100%;
  height: 100%;
}
  
.product-title {
  width: 100%;
  height: auto;    
  display: flex;    
  align-items: center; 
  justify-content: flex-start;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: grey;
  color: #515151;
}

.product-title h3{
  font-size: calc(16px + 0.8vw);
  font-weight: bold;
}

.productview-price {
  width: 100%;
  height: auto;
  padding-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: flex-start;
}

.productview-price h4{
    font-size: calc(18px + 0.5vw)
}

.product-old-price{
  position: relative;   
  margin-right: 5px;
  color: #6c6f74;
}

.product-old-price::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background: #383636;
  left: 0;
  top: 50%;    
}

  .html-text{      
    text-align: start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: calc(12px + 0.5vw);
    letter-spacing: 1px;
  }

  @media (max-width: 1250px) {
    .html-text {
        width: 169%;
    }
  }


  @media (max-width: 900px) {
    .html-text {
        width: 140%;
    }
  }

  .html-text ul{
      list-style-type: disc; 
      padding-left: 2vw;   
  }
  .html-text p{   
      margin-bottom: 0;
  }

.product-label {
  font-size: 22px;
  color: #fff;
  margin-bottom: 0px;
}

.wishList-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: flex-start;
      -ms-flex-align: flex-start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;  
  color: #fff;
  width: 17vh;
  height: 3vh;
  outline: none;
  border: none;
  border-radius: 3em;
}

.wishList-btn:hover {
  -webkit-box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
          box-shadow: 0 0 10px 0 #3498db inset, 0 0 10px 4px #3498db;
}

.product-mainBody .product-info .productInfo {
  width: 90%;
  height: auto;
  padding-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
  text-align: left;
}

.product-mainBody .product-info .productVariation {
  width: 90%;
  height: 10vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.product-mainBody .product-info .productStock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
  color: rgb(84, 165, 84);
}

.product-mainBody .product-info .productAddToCart {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: flex-start;
      -ms-flex-pack: flex-start;
          justify-content: flex-start;
}

.product-mainBody .product-info .productAddToCart button {
  margin-left: 5px;
}  

.product-evaluation-container {    
  display: flex;     
  flex-direction: column;
  align-items: center;    
  justify-content: center;
  min-width: 100vh;
  max-width: 200vh;
  min-height: 30vh;
  padding-top: 3vh;
  margin-left: 10vh;
  margin-right: 10vh;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.product-evaluation-container .product-evaluation-body {    
  display: flex;    
  flex-direction: column;    
  align-items: flex-start;    
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-top: 5vh;
  font-size: calc(10px + 0.5vw);
}

.product-evaluation-container .product-evaluation-body Textarea {
  width: 100%;
  margin-bottom: 10px;
}

.product-evaluation-title {      
  display: flex;      
  flex-direction: row;     
  align-items: center;     
  justify-content: flex-start;
  width: 100%;
  height: 3vh;
  font-weight: bold;
  border-bottom-width: 1px;
  border-bottom-color: grey;
  border-bottom-style: solid;
  padding: 5px;
  margin-top: 5vh;
}
