.cart-body {
    min-height: 50vh;   
    display: flex;    
    align-items: center;    
    justify-content: center;   
    flex-direction: column;   
    justify-content: flex-start;
}

.account-board {
    width: 60%;  
    min-height: 40vh;
    height: auto;
    border-style: solid;
    border-color: grey;
    border-width: 1px;
    margin-top: 5vh;
    margin-bottom: 10vh;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rated-card .style-comment-section{
    display: flex;
}

.rated-card .style-comment-section textarea {
    background-color: rgb(255, 252, 224); 
    width: 100%; 
    border-radius: 5px;
    padding: 5px 10px;
}

.rated-card .style-comment-section textarea:focus {
    background-color: rgb(255, 253, 239); 
    border: 1px solid rgb(209, 209, 209);
    outline: none;
}

.rated-card .comment-card-commentText {
    width: 100%;
}

@media screen and (max-width: 768px){
    .account-board {
       width:100%;     
    } 
}

.account-board-header {
    background-color: #c3c3c3;
    width: 100%;
    min-height: 5vh;
    border-bottom-color: #d9d9d9;
    border-bottom-width: 2px;
    border-bottom-style: solid; 
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; 
    align-items: center;  
    justify-content: space-evenly;
    flex-wrap: wrap;   
}

.account-board-header .title button {
  outline: none;
  border: none;
  background-color: #c3c3c3;
  color: #fff;  
  display: flex;
}

.account-board-header .title h4,
.changePassTitle h4{
    font-size: calc(18px + 0.5vw)
}

@media screen and (min-width: 1200px){
    .account-board-header .title h4,
    .changePassTitle h4{
        font-size: calc(24px)
    }
}

.icon-font-size{
    transform: scale(1.4);  
}

.account-board-body {
  padding-top: 2vh;
  padding-bottom: 2vh;
}