.footer {
    background-color: #333;
    width: 100%;
    color: #F2ECFF;
}

.footer a {
    color: #888888;
    text-decoration: none;
    transition: 0.2s;
}

.footer a:hover {
    text-decoration: none;
    color: #c2c2c2;
    cursor: pointer;
}

.footer-container {
    display: flex;
    flex-direction: column;
}

.row-flex-center {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
}

.row-flex-center .social-media-icons h3{
    font-weight: 700;
    letter-spacing: 3px;
    margin-top: -1em;
    font-size: 1.4em;
}

.row-flex-center .social-media-icons a {
    color: #F2ECFF;
}

.row-flex-center .social-media-icons a:hover {
    color: #c2c2c2;
}

.social-media-icons a svg {
    font-size: 2em;
    margin: 15px;
    transition: 0.3s;
}

.rules-use {
    padding-top: 1.3em;
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
}

.rules-use a:not(:first-child) {
    margin-top: 1em;
}

.draw-line {
    border-bottom: 1px solid rgb(80, 80, 80);
    display: block;
    width: 100%;
    margin: 25px auto 5px;
}

.copyright p {
    padding-top: 5px;
    font-size: 0.75em;
    margin-bottom: 0.3em;
    padding: 5px 0;
}

.copyright p a {
    color: #888888;
}

.copyright p a:hover {
    color: #c2c2c2;
}

.resume-title {
    margin-top: 1em;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.resume-about-section {
    width: 85%;
    margin: 2em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 650px;
    -webkit-box-shadow: 1px 1px 7px 3px #D1D1D1; 
    box-shadow: 1px 1px 7px 3px #D1D1D1;
}

@media (max-width: 1250px) {
    .resume-about-section {
        width: 90%;
    }
}

@media (max-width: 770px) {
    .resume-about-section {
        height: auto;
    }
}

@media (min-width: 640px) {
    .footer-container {
        width: 85%;
        margin: 2em auto 0;
        display: flex;
        flex-direction: column;
    }

    .row-flex-center {
        margin-top: 4em;
    }

    .row-flex-center .social-media-icons h3{
        margin-top: -2em;
        font-size: 1.7em;
    }

    .social-media-icons a svg {
        font-size: 2.5em;
    }

    .rules-use {
        flex-direction: row;
        justify-content: space-between;
        font-size: 1em;
        width: 100%;
    }

    .rules-use a:not(:first-child) {
        margin-top: 0;
    }

    .draw-line {
        width: 90%;
        margin: 25px auto 15px;
    }

    .copyright p {
        padding: 5px 0 15px;
        font-size: 0.9em;
        margin-bottom: 0.5em;
    }

}

@media (min-width: 1050px) {
    .footer-container {
        width: 75%;
        margin-top: 5em;
    }
    
    .row-flex-center {
        margin-top: 2em;
    }

    .social-media-icons a svg {
        font-size: 3em;
    }

    .social-media-icons a svg:hover {
        font-size: 3.2em;
    }

    .rules-use {
        font-size: 1.2em;
        flex-direction: row;
        justify-content: space-around;
    }

    .draw-line {
        width: 80%;
        margin: 35px auto 15px;
    }

    .copyright p {
        padding: 0.4em 0;
        font-size: 1em;
    }
}