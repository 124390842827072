.home-container{   
  background-color: #EAEDED;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-bottom: 2em;
}

.site-offers {
  min-height: 28vh;
  width: 100%;
}

.site-offers .offer-container {
  max-width: 100%;
  min-width: 520px;
  height: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
  justify-content: space-between;
}

.site-offers .offer-box {
  height: 300px;
  width: 250px;
  background-color: whitesmoke;
  -webkit-box-shadow: 1px 4px 13px 3px rgba(0, 0, 0, 0.34);
  box-shadow: 1px 4px 13px 3px rgba(0, 0, 0, 0.34);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  padding-right: 5px;
  padding-left: 5px;
}  

.categories-card-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
  justify-content: center; 
  margin-top: -30vh;
} 

.listproducts-container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap:wrap;
  margin: 3em auto;
  width: 95%;
  margin-bottom: 1em;
}

.resume-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #A6CBBE;
  width: 100%;
  height: 70vh;
}

.resume-card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  height: 50vh;
  background-color: rgb(234 237 237);
  width: 60%;
  border-radius: 10px;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 1px 5px 1px #C9C9C9; 
  box-shadow: 0px 1px 5px 1px #C9C9C9;
  color: #333;
}

.resume-card .resume-img {
  width: 27vh;
  height: 27vh;
  border-radius: 50%;
  -webkit-box-shadow: 1px 1px 7px 0px #B5B5B5; 
  box-shadow: 1px 1px 7px 0px #B5B5B5;
}

.resume-card .resume-text {
  width: 40%;
  text-align: justify;
}

.resume-card .resume-text h3 {
  letter-spacing: 2px;
}

.resume-card .resume-text p {
  padding: 1em 0;
}

.resume-card .resume-text .resume-learn-more {
  text-decoration: none;
  color: #5F6A72;
}

.resume-card .resume-text .resume-learn-more p {
  transition: 0.2s;
  letter-spacing: 1px;
  float: right;
}

.resume-card .resume-text .resume-learn-more p:hover {
  text-decoration: underline;
  letter-spacing: 1.5px;
}

@media screen and (max-width: 1050px) {
  .resume-card {
    width: 80%;
  }

  .resume-card .resume-img {
    width: 22vh;
    height: 22vh;
  }
}

@media screen and (max-width: 690px) {
  .resume-section {
    height: 70vh;
  }

  .resume-card {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    height: 95%;
    width: 80%;
  }

  .resume-card .resume-text {
    width: 60%;
    text-align: justify;
  }

  .resume-card .resume-text h3 {
    text-align: center;
    font-size: 20px;
  }

  .resume-card .resume-text p {
    padding: 1em 0 0;
    font-size: 14px;
  }

  .resume-card .resume-img {
    width: 16vh;
    height: 16vh;
  }
}

@media screen and (max-width: 1200px) {
  .categories-card-container {
  margin-top: -15vh; 
  }
  .site-offers .offer-container {
    max-width: 100%;
    min-width: 420px;
    height: 290px;
  }
  .site-offers .offer-box {
    height: 240px;
    width: 200px;
  }
  .product-container {
    margin-left: -1vw;
  }
}

@media screen and (max-width: 992px) {
  .categories-card-container {
  margin-top: -5vh; 
  }
  .site-offers .offer-container {
    max-width: 100%;
    min-width: 320px;
    height: 240px;
  }
  .site-offers .offer-box {
    height: 180px;
    width: 150px;
    line-height: 1rem;
  }
  .product-container {
    margin-left: -3vw;
  }
}