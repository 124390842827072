:root {
    --top_bar:hsl(212, 25%, 10%);
    --categories_bar: hsl(217, 10%, 25%);
    --amazon-orange-color: hsl(36, 100%, 50%);
    --amazon_orange-h: 36; 
    --amazon_orange-s: 100%;
    --amazon_orange-l: 50%;
    --main-text-color: #f1ebff;

    --yellow: #FFE699;
    --primary: #ea2c58;
    --secondary: #1fc281;
    --process: #c6e1c6;
    --completed: #c8d7e1;
    --canceled: #ff5c5c;
    --refunded: #ff9900;
    --deleted: #ff0000;
    --red: #b90039;
  }